import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Notification } from '@app/core/models/notification.model';
import { NotificationService } from '@app/core/services/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss',],
})
export class NotificationsComponent implements OnInit {

  notifications: Notification[] = [];
  // eslint-disable-next-line no-magic-numbers
  offset: number = 1;
  // eslint-disable-next-line no-magic-numbers
  limit: number = 20;
  totalNotifications: number;

  constructor(
    private _router: Router,
    private _notificationService: NotificationService,
    private _dialogRef: MatDialogRef<NotificationsComponent>
  ) { }

  ngOnInit(): void {
    this._getNotifications({ isRead: false, });
  }

  onSelectionChange(event: any) {
    const query: any = {};
    if (event.value != null) query['isRead'] = event.value;
    this._getNotifications(query);
    this.notifications = [];
  }

  _getNotifications(params: any) {
    this._notificationService.getNotifications(params).subscribe({
      next: (response) => {
        if (response && response.notifications && response.notifications.length) {
          this.notifications = this.notifications.concat(response.notifications);
        }
        this.totalNotifications = response.totalRecords;
      },
    });
  }

  viewNotification(notification: Notification) {
    this._router.navigateByUrl(notification?.action || '');
    this._notificationService.markAsRead(notification._id, {}).subscribe({
      next: (_response) => {
        this._dialogRef.close(true);
      },
    });
  }

  loadNext() {
    // eslint-disable-next-line no-magic-numbers
    this.offset = this.offset + 1;
    this._getNotifications({ offset: this.offset, });
  }

}
