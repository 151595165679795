import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationService } from '@app/core/services/notification.service';
import { NotificationsComponent } from './notifications/notifications.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss',],
})
export class HeaderComponent implements OnInit {

  // eslint-disable-next-line no-magic-numbers
  unreadNotificationsCount: number = 0;
  @Output() menuClick = new EventEmitter();

  constructor(
    private _router: Router,
    private _dialog: MatDialog,
    private _notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this._getNotifications();
  }

  _getNotifications() {
    this._notificationService.getNotifications({ isRead: false, }).subscribe({
      next: (notification) => {
        this.unreadNotificationsCount = notification.totalRecords;
      },
    });
  }

  navigateTo(path: string) {
    this._router.navigateByUrl(path);
  }

  viewNotification() {
    this._dialog
      .open(NotificationsComponent, {
        width: '25%',
        height: '100vh',
        autoFocus: false,
        position: {
          top: '0',
          right: '0',
        },
      }).afterClosed()
      .subscribe({
        next: (res) => {
          if (res) this._getNotifications();
        },
      });
  }

}
