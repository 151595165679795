import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { navList } from '@app/core/constants/sidenav';
import { IRole } from '@app/core/models/roles.model';
import { hasPermission, isAuthorized } from '@app/modules/main/shared/utils/utils';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss',],
})
export class SidenavComponent implements OnInit {
  folders = navList;
  selectedLink: string = 'home';

  @Output() menuClick = new EventEmitter();
  @Input() roles: IRole[] = [];

  constructor(private _router: Router) { }

  ngOnInit(): void {
    this.selectedLink = this._router.url.split('/')[1].toLowerCase();
  }

  navigateTo(path: string | undefined) {
    if (!path) return;
    this.menuClick.emit();
    this.selectedLink = path.toLowerCase();
  }

  _hasPermission(section: string): boolean {
    if (isAuthorized('admin')) return true;

    return hasPermission(section);
  }
}
