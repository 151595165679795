<ul>
  <li>
    <mat-form-field appearance="standard">
      <mat-label>Filter By</mat-label>
      <mat-select (selectionChange)="onSelectionChange($event)">
        <mat-option [value]="null">All</mat-option>
        <mat-option [value]="false">Unread</mat-option>
        <mat-option [value]="true">Read</mat-option>
      </mat-select>
    </mat-form-field>
  </li>
  <li><hr><br></li>
  <li [ngClass]="notification.isRead ? 'text-gray-500' : ''" *ngFor="let notification of notifications"
    class="cursor-pointer" (click)="viewNotification(notification)">
    <p class="text-base mb-0"><b>{{notification.title}}</b></p>
    <p class="text-base">{{notification.body}}</p>
    <p class="text-base italic">{{notification.createdAt | date : 'dd MMM yyy, hh:mm a'}}</p>
    <hr>
  </li>
  <li *ngIf="totalNotifications > limit" class="text-center mt-2">
    <button mat-raised-button (click)="loadNext()">Load More</button>
  </li>
</ul>