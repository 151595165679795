<div class="h-screen">
  <mat-sidenav-container hasBackdrop="false" class="h-screen">
    <mat-sidenav mode="side" [(opened)]="opened" class="w-1/6">
      <app-sidenav [roles]="roles" (menuClick)="sidenav.open()"></app-sidenav>
    </mat-sidenav>
    <mat-sidenav-content class="min-w-5/6 max-w-6/6">
      <app-header (menuClick)="sidenav.toggle()"></app-header>
      <div>
        <app-breadcrumb></app-breadcrumb>
        <div class="overflow-y-auto h-[90vh]">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>