export const SECTIONS = {
  ADMINS: 'ADMINS',
  MASTERS: 'MASTERS',
  CUSTOMERS: 'CUSTOMERS',
  VEHICLES: 'VEHICLES',
  PROCUREMENTS: 'PROCUREMENTS',
  FINANCES: 'FINANCES',
  MAINTENANCES: 'MAINTENANCES',
  DEALERS: 'DEALERS',
  CORPORATES: 'CORPORATES',
  PAYMENTS: 'PAYMENTS',
  COMMUNICATIONS: 'COMMUNICATIONS',
  E_RICKSHAW: 'E_RICKSHAW',
  QUOTATION: 'QUOTATION',
};

export const URL = {
  HOME: 'home',
  ADMIN: 'admin',
  ROLES: 'roles',
  BRAND: 'brand',
  MODEL: 'model',
  VARIANT: 'variant',
  WORKFLOW: 'workflow',
  VEHICLE: 'vehicle',
  PRICE_CALCULATOR: 'price-calculator',
  LISTED_VEHICLE: 'listed-vehicle',
  INVENTORY_VEHICLE: 'inventory-vehicle',
  ADD: 'add',
  ALL: 'all',
  DETAIL_PAGE: 'edit/',
  IMAGE_GALLERY: 'image-gallery',
  ORGANISATION: 'organisation',
  SUBSCRIPTION: 'subscription',
  SMART_WARRANTY: 'smart-warranty',
  PRODUCT: 'product',
  SERVICE: 'service',
  CORPORATE_LOCATION: 'corporate-location',
  CORPORATE_TYPE: 'corporate-type',
  CORPORATE_MASTER: 'corporate-master',
  MASTER_LEASE_AGREEMENT: 'master-lease-agreement',
  CORPORATE_ADMIN: 'corporate-admin',
  CORPORATE_SUBSCRIPTION: 'corporate-subscription',
  CORPORATE_USER: 'corporate-user',
  CUSTOMERS: 'customers',
  DEALER: 'dealer',
  DEALER_ADMIN: 'dealer-admin',
  BANK_ACCOUNT: 'bank-accounts',
  CONTACT_US: 'contact-us',
  SUPPORT: 'support',
  EMAIL_TEMPLATE: 'email-template',
  APPROVAL: 'approvals',
  INSPECTION_REQUEST: 'inspection-request',
  PROCUREMENT_REQUEST: 'procurement-request',
  PROSPECT_VEHICLE: 'prospect-vehicle',
  SMART_LOAN: 'smart-loan',
  LOCATION: 'location',
  INSPECTION: 'inspection',
  AUTO_DEBIT: 'auto-debit',
  PRICE_DISCOVERY: 'price-discovery',
  FINANCE_PARTNER: 'finance-partner',
  CAR_FINANCE: 'car-finance',
  WORKSHOP: 'workshop',
  PUD_AGENCY: 'pud-agency',
  BUSINESS_UNIT: 'business-unit',
  SAM_REQUEST: 'sam-request',
  PUD_REQUEST: 'pud-request',
  SERVICE_AND_MAINTENANCE: 'service-and-maintenance',
  INSURANCE: 'insurance',
  PUC: 'puc',
  RC: 'rc',
  FINAL_DOCUMENTATION: 'final-documentation',
  RTO_VENDORS: 'rto-vendors',
  RTO: 'rto',
  NOTIFICATION_CHANNEL: 'notification-channel',
  FINANCE: 'finance',
  FINANCE_PARTNER_ADMIN: 'finance-partner-admins',
  TESTING_ACCOUNT: 'testing-account',
  GENERAL_VEHICLE: 'general-vehicle',
  PAYMENT_SCHEDULE: 'payment-schedule',
  E_RICKSHAW_SUBSCRIPTION: 'e-rickshaw-subscription',
  QUOTATIONS: 'quotations',
  QUOTATION_REQUEST: 'quotation-request',
  ON_ROAD_PRICE: 'on-road-price',
  INVOICE_SCHEDULE: 'invoice-schedule',
};
