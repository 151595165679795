import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitBy',
  pure: true,
})
export class splitByPipe implements PipeTransform {

  // splits a value by underscore e.g. work_info = work info

  transform(value: string, args: string = '_') {
    if (value) {
      // Split by capital letters maxPower -> max Power
      if (args === 'caps') return value.split(/([A-Z]?[^A-Z]*)/g).join(' ');
      return value.split(args).join(' ');
    } else return value;
  }
}

