import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '../models';
import { Notification } from '../models/notification.model';

type NotificationResponse = { notifications: Notification[], totalRecords: number }

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  apiUrl = `${environment.apiBaseUrl}/notification/v1/notifications/`;

  constructor(private _httpClient: HttpClient) { }

  getNotifications(params: any): Observable<NotificationResponse> {
    return this._httpClient.get<ApiResponse<any>>(this.apiUrl, { params: params, })
      .pipe(map((res) => res.data));
  }

  markAsRead(id: string, body: any): Observable<any> {
    return this._httpClient.patch<{ data: Notification }>(this.apiUrl + `mark-as-read/${id}`, body);
  }

}
