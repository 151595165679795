import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { IRole } from '@app/core/models/roles.model';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss',],
})
export class MainComponent implements OnInit {

  @ViewChild(MatSidenav) sidenav: MatSidenav;
  adminData: any;
  roles: IRole[] = [];
  opened: boolean = true;

  constructor(private _authService: AuthService) { }

  ngOnInit(): void {
    const { entity, } = this._authService.getLoginData();
    this.adminData = entity;
    this.roles = this.adminData?.roles || [];
  }


}
