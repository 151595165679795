<mat-toolbar>
  <button (click)="menuClick.emit()" aria-label="Example icon-button with menu icon">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="example-spacer"></span>
  <!-- <button  (click)="navigateTo('notification/email')" aria-label="email" >
    <mat-icon>email</mat-icon>
  </button> -->
  <button mat-icon-button (click)="viewNotification()" aria-label="notifications" class="mx-4" matTooltip="View Notifications">
    <mat-icon *ngIf="unreadNotificationsCount" [matBadge]="unreadNotificationsCount" matBadgePosition="below" matBadgeColor="accent">
      notifications</mat-icon>
    <mat-icon *ngIf="!unreadNotificationsCount">notifications</mat-icon>
  </button>
  <button (click)="navigateTo('profile')" aria-label="profile" matTooltip="View Profile">
    <mat-icon class="pt-3">person</mat-icon>
  </button>
</mat-toolbar>