import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss',],
})
export class BreadcrumbComponent {

  pathItems: string[] = [];

  constructor(private _router: Router) {

    this._router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url)
      )
      .subscribe(url => {
        this.pathItems = [];
        if (!url || url === '/') return;
        // eslint-disable-next-line no-magic-numbers
        this.pathItems = url.split('/').slice(0, 3);
        this.pathItems.pop();
      });

  }


}


