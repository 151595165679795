/* eslint-disable no-magic-numbers */
export class AppConstants {
  static readonly API_BASE_URL = 'http://dev-api.pumpumpum.com';
  static readonly AUTH_TOKEN_KEY = 'AUTH_TOKEN_KEY';
  static readonly AUTH_DATA_KEY = 'AUTH_DATA_KEY';
  static readonly LS_BRAND_KEY = '__brand_storage__';
  static readonly LS_MODEL_KEY = '__model_storage__';
  static readonly LS_VARIANT_KEY = '__variant_storage__';
  static readonly LS_VEHICLE_KEY = '__vehicle_storage__';

  static readonly LS_VEHICLE_SEARCH_KEY = '__vehicle_search__';
  static readonly LS_VARIANT_PRICING_KEY = '__vehicle_pricing__';
  static readonly LS_DEFVALUES_VARIANT_PRICING_KEY = '__defValues_vehicle_pricing__';


  static readonly LS_INVENTORY_VEHICLE_KEY = '__inventory_vehicle_storage__';

  static readonly LS_CUSTOMER_KEY = '__customer_storage__';
  static readonly LS_CUSTOMER_PROFILE_KEY = '__customer_profile_storage__';   //TODO - to be removed
  static readonly LS_CUSTOMER_DETAILS_KEY = '__customer_details_storage__';
  static readonly LS_IMAGEGALLERY_KEY = '__ imagegallery_storage__';
  static readonly LS_IMAGEDATA_KEY = {
    EXTERIOR: '__ image_exterior__',
    INTERIOR: '__ image_interior__',
    CAROUSEL: '__ image_carousel__',
    FEATURED: '__ image_featured__',
  };
  static readonly LS_VARIANT_FEATURES_KEY = '__variant_features__';
  static readonly LS_VARIANT_SPECS_KEY = '__variant_specs__';
  static readonly LS_ORGANISATION_KEY = '__organisation__';
  static readonly LS_SUBSCRIPTION_KEY = '__subscription__';
  static readonly LS_PRODUCT_KEY = '__product__';
  static readonly LS_SERVICE_KEY = '__services__';
  static readonly LS_CORPORATE_KEY = '__corporate__';
  static readonly LS_CORPORATE_ADMIN_KEY = '__corporateAdmin__';
  static readonly LS_CORPORATE_PROFILE_KEY = '__corporate_profile__';
  static readonly LS_SUBS_SUGGESTED_VEH_KEY = '__subs_sugg_veh__';
  static readonly LS_SUBS_SELECTED_VEH_KEY = '__subs_selected_veh__';
  static readonly LS_SUBS_STEPS_KEY = '__subs_steps_veh__';
  static readonly LS_EMAILTEMPLATE_KEY = '__emailTemplate__';


  static readonly LS_SUPPORT_KEY = '__support_user__';

  static readonly LS_NAVIGATE = '__navigate_to__';

  static readonly LS_BRAND_FILTER = 'brand_filter';
  static readonly LS_MODEL_FILTER = 'model_filter';
  static readonly LS_VARIANT_FILTER = 'variant_filter';
  static readonly LS_LISTED_VEHICLE_FILTER = 'listed_vehicle_filter';
  static readonly LS_INVENTORY_VEHICLE_FILTER = 'inventory_vehicle-filter';
  static readonly LS_RETAIL_CUSTOMER_FILTER = 'retail_customer-filter';


  static readonly LS_CUSTOMER_CURRENT_TAB = 'customer_current_tab';
  static readonly ZERO = 0;
  static readonly NEGATIVE_ONE = -1;
  static readonly ONE = 1;
  static readonly TWO = 2;
  static readonly MAX_ADVANCE_MONTHS = 5;
  static readonly SEVEN = 7;
  static readonly FIVE_YEARS = 1825;
  static readonly PAGESIZE = 10;
  static readonly HUNDRED = 100;
  static readonly YEARSCOUNT = 15;
  static readonly DAYSCOUNT = 30;
  static readonly SEARCH_DEBOUNCE = 500;
  static readonly GST_MIN_LENGTH = 15;
  static readonly ACCOUNT_NUMBER_LENGTH = 11;

  static readonly DATA_TABLE_STRING_COMPARISON_LENGTH = 30;
  static readonly DATA_TABLE_ARRAY_COMPARISON_LENGTH = 3;

  static readonly PHONE_PATTERN = /^[6789]{1}[0-9]{9}$/;
  static readonly EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static readonly PINCODE_PATTERN = /\b\d{6}\b/;
  static readonly GST_PATTERN = /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
  static readonly PAN_PATTERN = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  static readonly REG_NUM_PATTERN = /^[a-zA-Z0-9]+$/;
  static readonly AADHAR_PATTERN = /\b\d{12}\b/;
}

export const FilterType = {
  brand: 'brand',
  model: 'model',
  variant: 'variant',
  all: 'all',
};

export const FilterEndPoint = {
  customers: 'user/v1/customers/filters',
};


export const CUSTOMER_TYPE = {
  RETAIL: 'RETAIL',
  CORPORATE: 'CORPORATE',
};

export const PUD_COLUMNS = [
  {
    key: 'regNumber',
    headerName: 'RC Number',
    type: 'text',
  },
  {
    key: 'samCaseID',
    headerName: 'SAM-ID ',
    type: 'text',
  },
  {
    key: 'service',
    headerName: 'Service',
    type: 'text',
  },
  {
    key: 'status',
    headerName: 'Status ',
    type: 'text',
  },
  {
    key: 'pickOrDropBy',
    headerName: 'Pick Or Drop By',
    type: 'text',
  },
  {
    key: 'scheduleDate',
    headerName: 'Schedule Date',
    type: 'date',
  },
  {
    key: 'scheduleTime',
    headerName: 'Schedule Time Range',
    type: 'text',
  },
  {
    key: 'pickOrDropDate',
    headerName: 'Pick Or Drop Date',
    type: 'date',
  },
  {
    key: 'pickOrDropTime',
    headerName: 'Pick Or Drop Time',
    type: 'date-time',
  },
  {
    key: 'chaufferName',
    headerName: 'Chauffer Name',
    type: 'text',
  },
  {
    key: 'chaufferEmail',
    headerName: 'Chauffer Email',
    type: 'text',
  },
  {
    key: 'chaufferMobile',
    headerName: 'Chauffer Mobile',
    type: 'text',
  },
  {
    key: 'remarks',
    headerName: 'Remarks',
    type: 'text',
  },
  {
    key: 'createdAt',
    headerName: 'Created At',
    type: 'date-time',
  },
];

export const PR_COLUMNS = [
  {
    key: 'caseId',
    headerName: 'PR-ID',
    type: 'text',
  },
  {
    key: 'createdAt',
    headerName: 'Requested On',
    type: 'date-time',
  },
  {
    key: 'crmUID',
    headerName: 'CRM UID',
    type: 'text',
  },
  {
    key: 'status',
    headerName: 'Status',
    type: 'text',
  },
  {
    key: 'brand',
    headerName: 'Brand',
    type: 'text',
  },
  {
    key: 'model',
    headerName: 'Model',
    type: 'text',
  },
  {
    key: 'variant',
    headerName: 'Variant',
    type: 'text',
  },
  {
    key: 'year',
    headerName: 'Year',
    type: 'number',
  },
  {
    key: 'stateRTO',
    headerName: 'State RTO',
    type: 'text',
  },
  {
    key: 'product',
    headerName: 'Product',
    type: 'text',
  },
  {
    key: 'city',
    displayKey: 'name',
    headerName: 'City',
    type: 'object',
  },
  {
    key: 'transmission',
    headerName: 'Transmission',
    type: 'text',
  },
  {
    key: 'fuelType',
    headerName: 'Fuel Type',
    type: 'text',
  },
  {
    key: 'color',
    headerName: 'Color',
    type: 'text',
  },
  {
    key: 'tat',
    headerName: 'Estimated TAT',
    type: 'date',
  },
  {
    key: 'lastOrNextAction',
    headerName: 'Last/Next Action by',
    type: 'text',
  },
  {
    key: 'lastOrNextLevel',
    headerName: 'Last/Next Level',
    type: 'text',
  },
  {
    key: 'estimatedPrice',
    headerName: 'Estimated Price',
    type: 'price',
  },
  {
    key: 'ownership',
    headerName: 'Ownership Count',
    type: 'number',
  },
  {
    key: 'requestedBy',
    displayKey: 'firstName',
    headerName: 'Requested By',
    type: 'object',
  },
  {
    key: 'lastRemark',
    headerName: 'Last Remark',
    type: 'text',
  },
];