<a routerLink="">
  <img src="../../../../assets/images/logo.svg" class="p-4 w-full" alt="PumPumPum Logo">
</a>
<mat-accordion displayMode="flat">
  <ng-container *ngFor="let folder of folders">
    <mat-expansion-panel *ngIf="_hasPermission(folder.path)">
      <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
        <mat-panel-title class="flex items-center gap-4">
          <mat-icon class="text-md">{{folder.icon}}</mat-icon>
          <div class="text-base">{{folder.name}}</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let subFolder of folder?.subFolders">
        <mat-list [disableRipple]="false" role="list" dense *ngIf="_hasPermission(subFolder.path)">
          <a [routerLink]="subFolder.path">
            <mat-list-item (click)="navigateTo(subFolder.path)" [class.nav-selected]="subFolder.path === selectedLink"
              [disableRipple]="false" role="listitem" class="hover:bg-gray-50 !h-12">
              <mat-icon mat-list-icon>{{subFolder.icon}}</mat-icon>
              <div>{{subFolder.name}}</div>
            </mat-list-item>
          </a>
        </mat-list>
      </ng-container>
    </mat-expansion-panel>
  </ng-container>
</mat-accordion>