import { AppConstants } from '@app/core/constants/common.const';
import { IRole } from '@app/core/models/roles.model';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';

export function excludeNullFromObject(object: any) {
  return _.omitBy(cleanEmptyObject(object), (i) => {
    return typeof (i) === 'object' && _.isEmpty(i);
  }
  );
}

export function cleanEmptyObject(obj: any): any {
  return Object.entries(obj)
    .map(([k, v,]) => [k, v && typeof (v) === 'object' ? cleanEmptyObject(v) : v,])
    .reduce((a: any, [k, v,]: any[]) => (v == null ? a : (a[k] = v, a)), {});
}

// https://stackoverflow.com/a/40517249
export const removeNullAndUndefinedFromObject = (object: object) => JSON.parse(JSON.stringify(object, (_, value) => value ?? undefined));


export const isAuthorized = (role: string): boolean => {
  const data = localStorage.getItem(AppConstants.AUTH_DATA_KEY);
  const loginData = data ? JSON.parse(data) : null;
  if (!loginData) return false;
  return loginData.entity?.roles?.some((r: IRole) => ['admin', role,].includes(r.label.toLowerCase())) || false;
};

export const hasPermission = (endpoint: string): boolean => {
  const data = localStorage.getItem(AppConstants.AUTH_DATA_KEY);
  const loginData = data ? JSON.parse(data) : null;
  if (!loginData) return false;
  const permissions: string[] = [];
  loginData?.entity?.roles?.forEach((role: any) => {
    permissions.push(...permissions, ...role.permissions);
  });
  return permissions.includes(endpoint);
};

export const dataUrlToFile = async (dataUrl: string, fileName: string): Promise<File> => {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob,], fileName, { type: 'image/png', });
};

export const isValidDate = (dateString: string): boolean => {
  const date = new Date(dateString);
  return !isNaN(date.getTime()) && date instanceof Date;
};

export const parseExcelToJson = (event: any): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);

    reader.onload = (e: any) => {
      try {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary', });

        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* to get 2d array pass 2nd parameter as object {header: 1} */
        const parsedJSON = XLSX.utils.sheet_to_json(ws, {
          blankrows: true,
          defval: null,
        });

        /* Resolve the Promise with the parsed data */
        resolve(parsedJSON);
      } catch (error) {
        /* Reject the Promise if there's an error */
        reject(error);
      }
    };

    reader.onerror = (error) => {
      /* Reject the Promise if there's an error reading the file */
      reject(error);
    };
  });
};